/* eslint-disable import/extensions */

// Services Img Imports
import Web from '../assets/images/Services/Web.png';
import Mobile from '../assets/images/Services/Mobile.png';
import UIUX from '../assets/images/Services/Design.png';

// Portfolio Img Imports

import Stream from '../assets/images/Portfolio/2.jpg';
import Freelance from '../assets/images/Portfolio/3.jpg';
import Aura from '../assets/images/Portfolio/4.jpg';
import Surtido from '../assets/images/Portfolio/5.jpg';
import ManagementApp from '../assets/images/Portfolio/6.jpg';
import ManagementAp from '../assets/images/Portfolio/7.jpg';
import ManagementA from '../assets/images/Portfolio/8.jpg';
import Managemen from '../assets/images/Portfolio/9.jpg';
import Manageme from '../assets/images/Portfolio/10.jpg';
import Recruiting from '../assets/images/Portfolio/1.jpg';

// Advantages
import Communicative from '../assets/images/Advantages/Communicative.png';
import Collaborative from '../assets/images/Advantages/Collaborative.png';
import Management from '../assets/images/Advantages/Management.png';
import Favorite from '../assets/images/Advantages/Favorite.png';

// TeamMembers
import Founder from '../assets/images/TeamMembers/Founder.jpeg';
import CoFounder from '../assets/images/TeamMembers/CoFounder.jpeg';
import WebDeveloper from '../assets/images/TeamMembers/WebDeveloper.jpeg';
import FrontendDeveloper from '../assets/images/TeamMembers/FlutterFrontendDeveloper.jpeg';
import FigmaDesigner from '../assets/images/TeamMembers/FigmaDesigner.jpeg';
import FlutterDeveloper from '../assets/images/TeamMembers/FlutterDeveloper.jpeg';
import BackendDevelpor from '../assets/images/TeamMembers/BackendDevelpor.jpeg';

// Testimonials
import review1 from '../assets/images/Testimonials/r1.jpeg';
import review2 from '../assets/images/Testimonials/r2.jpeg';
import review3 from '../assets/images/Testimonials/r3.jpeg';
import review4 from '../assets/images/Testimonials/r4.jpeg';
import review5 from '../assets/images/Testimonials/r5.jpeg';
import review6 from '../assets/images/Testimonials/r6.jpeg';
import review7 from '../assets/images/Testimonials/r7.jpeg';
import review8 from '../assets/images/Testimonials/r8.jpeg';
// import review9 from '../assets/images/Testimonials/r9.jpeg';

export const Services = [
  {
    title: 'Web Development',
    imageUrl: Web,
    animation: 'left',
  },
  {
    title: 'Mobile Development',
    imageUrl: Mobile,
    animation: 'up',
  },
  {
    title: 'UI/UX Design',
    imageUrl: UIUX,
    animation: 'right',
  },
];

export const Portfolios = [
  {
    id: 'asd1293uvbvcbbd3',
    title: 'Diciannovia – Live Stream & Compete',
    imageUrl: Freelance,
    type: 'Mobile App',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    Detail: 'Diciannovia is a social media app that blends the content-sharing capabilities of TikTok and Instagram with a unique live-streaming and gifting feature. Developed using Flutter, Firebase, and Agora RTC, Diciannovia enables users to engage in live-streaming competitions, providing a highly interactive experience. Key features include: Three-Round Competitions: Users can participate in live competitions with two challenge types: "winner takes all" or a reverse competition model where gifts are distributed based on various criteria. Gifting System: During live streams, users can earn or send gifts, enhancing the competitive atmosphere and providing additional earning opportunities. This dynamic platform offers a fresh take on social media by integrating competition and user rewards, creating an engaging and rewarding user experience.',
   
  },
  {
    id: 'asd1293ufgdfgs4',
    title: 'Wellwait – Service Booking Made Easy',
    imageUrl: Aura,
    type: 'Mobile App',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    Detail: 'Wellwait is an upcoming service-booking platform designed for small businesses, particularly service providers like salons and spas, to list their services and allow users to book appointments seamlessly. Developed using Flutter and Node.js, the app focuses on simplifying the service management and booking process for both businesses and customers. Key features include: Chatting Module: Allows users to communicate directly with service providers, ensuring clarity on bookings and services. Search Module: Users can easily find services based on their location, desired time slots, and service type. Auth Module: Provides a secure authentication system, with the option to sign up and log in using Google accounts. With additional features like calendar integration and payment gateways planned, Wellwait aims to be a comprehensive solution for booking services with ease.',
   
  },
  {
    id: 'asd1293ulskmnb5',
    title: 'Runwey – Fashion Designer Platform',
    imageUrl: Surtido,
    type: 'Mobile App',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    Detail: 'Runwey is a social media platform tailored for fashion designers to showcase their collections and reach a broader audience. Built with Flutter and Node.js, and utilizing a continuous integration/continuous deployment (CI/CD) pipeline, Runwey ensures smooth operation and frequent updates to its users. Key features include: Filter Posts: Users can filter posts based on gender, occasion, and popularity, allowing them to find content that matches their specific tastes. Trending Fashion: Fashion designers can upload their collections and receive real-time feedback from the community, while users can stay updated on the latest trends in the fashion industry. Runwey creates an engaging platform for both designers and fashion enthusiasts, fostering a vibrant community centered around style and creativity.',
  
  },
  {
    id: 'asd1293ulkmnbj6',
    title: 'Tangerine – AI/AR Avatar Social App',
    imageUrl: ManagementApp,
    type: 'Mobile App',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    Detail: 'Tangerine is an innovative social media platform that focuses exclusively on AI and AR-generated avatar content. Built with Flutter and Firebase, and integrated with OpenAI, this app provides a futuristic space for users to express their creativity through advanced AI-enhanced visuals. The app allows users to create and share personalized avatars and AI-generated content, providing a unique social experience that blends technology and art. By leveraging OpenAI, Tangerine offers users the ability to generate content that’s visually stunning and unique, fostering a creative community where AI and human creativity merge seamlessly. This platform is perfect for users interested in the latest advancements in AR, AI, and digital expression.',

  },
  {
    id: 'asd1293uasdads1',
    title: 'Aawre.ai – AI Interaction Analysis',
    imageUrl: Recruiting,
    type: 'Mobile App',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ], 
    Detail: 'Aawre.ai is an advanced mobile solution built with Flutter, Python, and Firebase, designed to provide insights by analyzing human interaction patterns using multimodal data. It leverages cutting-edge machine learning models to process inputs like eye and gaze movements, facial features, and physiological sensors (when available). This innovative app goes beyond traditional data analysis by utilizing smartphone technology to explore the subtleties of human behavior. /n The Aawre.ai team is a diverse group, bringing together expertise from various fields and cultures. This includes a pilot who conducted research on the concept, two AI experts responsible for developing the core machine learning models, and a UI/UX specialist who crafted the user-friendly interface, ensuring that the app not only performs well but is intuitive and engaging for users',
    
  },
  {
    id: 'asd1293ulkmnbj7',
    title: 'Cylinder Sales App – Gas Ordering Simplified',
    imageUrl: ManagementAp,
    type: 'Mobile App',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    Detail: 'The Cylinder Sales App is designed to provide an easy and efficient way to purchase gas cylinders. Built with Flutter and Firebase, this app is still under development but already has a fully designed front-end and an implemented authentication system using Firebase. The app will allow users to browse different types of gas cylinders, add them to their cart, and make secure purchases. The Firebase integration ensures that the app’s data is stored securely, while the Flutter framework guarantees a seamless user experience. Future development phases will include payment gateways, order tracking, and delivery scheduling features.',
  
  },
  {
    id: 'asd1293ulkmnbj8',
    title: 'Sleep Tracker – Monitor & Improve Sleep',
    imageUrl: ManagementA,
    type: 'Mobile App',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    Detail: 'This Sleep Tracker app is inspired by popular apps like Better Sleep and is designed to help users track their sleep patterns and develop healthier habits. Developed using Flutter and Firebase, it provides users with insights into their sleep cycles and suggestions on how to improve their sleep quality. The app offers: Sleep Monitoring: Tracks users sleep cycles and provides detailed reports on sleep duration and quality. Sleep Insights: Suggests personalized tips and routines to help users improve their sleep based on their tracked data. User-Friendly Interface: The app’s intuitive UI allows users to easily navigate and monitor their sleep health, making it a valuable tool for improving sleep habits.',
  
  },
  {
    id: 'asd1293ulkmnbj9',
    title: 'Coffee Shop – Order Coffee Seamlessly',
    imageUrl: Managemen,
    type: 'Mobile App',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    Detail: 'The Coffee Shop App is designed for a coffee restaurant, providing users with an intuitive platform to explore, browse, and order coffee products. Built with Flutter and Firebase, it offers a seamless experience for customers to interact with the coffee menu, order their favorite drinks, and stay updated on special promotions. Key features include: Menu Browsing: Customers can easily navigate through the coffee menu, exploring various options such as specialty coffees, seasonal offerings, and add-ons. Ordering System: The app allows users to place orders directly through the app, with an integrated checkout system. Firebase Integration: Ensures secure data handling for user accounts, orders, and payments. This app enhances the coffee shopping experience by making it convenient and engaging, whether customers are browsing from their homes or placing an order on the go.',
 
  },
  {
    id: 'asd1293ulkmnbj10',
    title: 'Money Talk Arena – Monetize & Compete',
    imageUrl: Manageme,
    type: 'Mobile App',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    Detail: 'Money Talk Arena is a comprehensive social network platform tailored for entrepreneurs to sell videos and content while engaging in competitions. Built with monetization at its core, it features user interaction through likes, views, comments, and follows. The app offers live streaming similar to Twitch and TikTok, along with a marketplace and commission-based content sales. Entrepreneurs can build their networks, participate in themed competitions, and monetize based on follower count. It also includes targeted ads, subscriptions, digital wallet integration, and a rewards system to encourage user engagement. This innovative platform empowers entrepreneurs to expand their reach and revenue in various industries, from SaaS to dropshipping, AI, and more.',
  
  }, 
  {
    id: 'asd1293uhjkhkjh2',
    title: 'Podzter – Podcast Match & Collaborate',
    imageUrl: Stream,
    type: 'Mobile App',
    responsibility: [
      'Mobile Development',
      'UI/UX Design',
    ],
    Detail: 'Podzter is a social networking platform designed to bridge the gap between podcast hosts and potential guests through a powerful matching algorithm. Built using Flutter and Firebase, Podzter enhances the user experience with a sleek, intuitive UI, making it easier for users to connect and collaborate on podcasts. The app includes several key features: Chatting Module: Facilitates seamless communication between hosts and guests, allowing them to discuss episode ideas, schedules, and other podcast-related topics. Search Module: A powerful search feature helps users quickly find suitable podcast hosts or guests based on various criteria. Auth Module: Provides a secure and efficient authentication process, enabling users to sign up and log in using their Google accounts, ensuring ease of access and improved security. These features make Podzter a go-to platform for podcast creators looking to collaborate and expand their audience.',
   
  },
];

export const Advantages = [
  [{
    title: 'Communicative',
    description: 'We communicate our project ideas and progress to make it clear.',
    imageUrl: Communicative,
  },
  {
    title: 'Management',
    description: 'We manage our project properly to make our project done well.',
    imageUrl: Management,
  }],
  [{
    title: 'Collaborative​',
    description: 'Our team are very collaborative to make our project done well.',
    imageUrl: Collaborative,
  },
  {
    title: 'Favorite',
    description: "We've did so many project and all of our client love it.",
    imageUrl: Favorite,
  }],
];
export const Testimonials = [
  // {
  // uncomment this and the code in testimonials
  // file to use the image, name , comapny review layout of testimonials.
  //   id: 1,
  //   name: 'Safyan Tariq',
  //   company: 'Owner, Surveyor Corps',
  //   testimoni: 'Thanks for RapidRiseTech, you guys are the best! Keep up the great work!',
  //   imageUrl: review1,
  // },
  // {
  //   id: 2,
  //   name: 'Hammad Malhi',
  //   company: 'Director, Malhi Rice mill',
  //   testimoni: 'I just wanted to let you know that it’s been great working with RapidRiseTech.',
  //   imageUrl: FrontendDeveloper,
  // },
  // {
  //   id: 3,
  //   name: 'Huzaifa Ahmed',
  //   company: 'CEO, BrosVilla',
  //   testimoni: 'RapidRiseTech is so great. Thank you so much for a job well done.',
  //   imageUrl: FigmaDesigner,
  // },
  {
    id: 1,
    imageUrl: review1,
  },
  {
    id: 2,
    imageUrl: review2,
  }, 
  {
    id: 3,
    imageUrl: review3,
  }, 
  {
    id: 4,
    imageUrl: review4,
  }, 
  
  {
    id: 6,
    imageUrl: review6,
  }, 
  {
    id: 5,
    imageUrl: review5,
  }, 
  {
    id: 7,
    imageUrl: review7,
  },
  {
    id: 8,
    imageUrl: review8,
  },
  // {
  //   id: 9,
  //   imageUrl: review9,
  // },
];

export const TeamMembers = [
  {
    name: 'Safyan Tariq',
    position: 'CEO',
    imageUrl: Founder,
  },
  {
    name: 'Farhan Tariq',
    position: 'Co-Founder',
    imageUrl: CoFounder,
  },
  {
    name: 'Hammad Malhi',
    position: 'Front-end Developer',
    imageUrl: FrontendDeveloper,
  },
  {
    name: 'Faizan Masood',
    position: 'Web Developer',
    imageUrl: WebDeveloper,
  },
  {
    name: 'Huzaifa Ahmed',
    position: 'Figma Designer',
    imageUrl: FigmaDesigner,
  },
  {
    name: 'Sher Afgan',
    position: 'Flutter Developer',
    imageUrl: FlutterDeveloper,
  },
  {
    name: 'Fahad Tariq',
    position: 'Social Media Manager',
    imageUrl: BackendDevelpor,
  },
  
];
